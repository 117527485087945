import { Validator } from 'vee-validate';
import { isNullOrUndefined } from 'util';

function isLegalAge(values) {
  if (!isNullOrUndefined(values.date)) {
    if (values.date > values.minDate) {
      return false;
    }
  }
  return true;
}

Validator.extend('legalAge', {
  getMessage: () => '',
  validate: (values) => isLegalAge(values)
}, {
    hasTarget: true
  });
