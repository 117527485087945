import axios from 'axios';
import { Config } from '../config';

export default {
  async setQuotes(context, quotes) {
    context.commit('setQuotes', quotes);
  },

  async setCurrentProject(context, project) {
    context.commit('setProject', project);
  },

  async setCurrentProjectIsModified(context, currentProjectIsModified) {
    context.commit('setCurrentProjectIsModified', currentProjectIsModified);
  },

  async setPartnerIsShow(context, partnerIsShow) {
    context.commit('setPartnerIsShow', partnerIsShow);
  },

  async getCountries(context) {
    context.commit('setLoading', true);

    let response = await axios.post(Config.API_ENDPOINT + '/api/Project/GetCountries', {});
    context.commit('setCountries', response.data);

    context.commit('setLoading', false);

  },


  async getPastProjects(context, model) {
    context.commit('setLoading', true);

    let response = await axios.post(Config.API_ENDPOINT + '/api/Project/GetProjectHistory', model);

    context.commit('setPastProjects', response.data);

    context.commit('setLoading', false);

  },

  async downloadPdf(context, model) {
    context.commit('setLoading', true);

    let response = await axios.post(Config.API_ENDPOINT + '/api/Project/GetPDF', model);

    if (response.data && response.data.content) {

      var binaryString = window.atob(response.data.content);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }

      var blob = new Blob([bytes]);
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      var fileName = response.data.fileName;
      link.download = fileName;
      link.click();
    }

    // context.commit('setPastProjects', response.data);

    context.commit('setLoading', false);

  },

  async generateProject(context, builtProject) {
    context.commit('setLoading', true);
    try {
      let response = await axios.post(Config.API_ENDPOINT + '/api/Project/GenerateProject', builtProject);

      context.commit('setQuotesId', response.data.quotes);

      context.commit('setSaveSuccess', { IsSaved: true, QuoteId: builtProject.CurrentQuoteIdToSend });
    }
    catch (e) {
      this.dispatch('app/setError', 500, { root: true });
      context.commit('setSaveSuccess', { IsSaved: false, QuoteId: builtProject.CurrentQuoteIdToSend });
    }
    finally {
      context.commit('setLoading', false);
    }

  },



};

