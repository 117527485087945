export default {
  setQuotes(state, quotes) {
    state.quotes = quotes;
  },
  setQuotesId(state, quotes) {
    var clientQuotesId = state.quotes.map(function (quote) { return quote.QuoteId; });

    for (var quote of quotes) {
      let index = clientQuotesId.indexOf(quote.quoteId);

      if (index >= 0) {
        var clientQuote = state.quotes[index];
        
        clientQuote.QuoteReferenceId = quote.quoteReferenceId;
        clientQuote.QuoteReferenceIdPrefix = quote.quoteReferenceIdPrefix;
        clientQuote.QuoteReferenceIdSuffix = quote.quoteReferenceIdSuffix;

        state.quotes.splice(index, 1, clientQuote);
      }
    }
  },
  setPartnerIsShow(state, partnerIsShow) {
    state.partnerIsShow = partnerIsShow;
  },
  setProject(state, project) {
    state.currentProject = project;
  },
  setCountries(state, countries) {
    state.countries = countries;
  },
  setPastProjects(state, model) {
    state.pastProjects = model.projects;
    state.totalPages = model.totalPages;
  },
  setLoading(state, isLoading) {
    state.isLoadingGenerateProject = isLoading;
  },
  setCurrentProjectIsModified(state, currentProjectIsModified) {
    state.currentProjectIsModified = currentProjectIsModified;
  },
  setSaveSuccess(state, argsProjectSaved) {
    state.savedQuote = argsProjectSaved;
  }
};
