import actions from './actions.js';
import mutations from './mutations.js';
import State, { transientFields } from './state.js';

const name = 'app';

function keepPersistentFields(state) {
  const result = { ...state };
  for (const key in transientFields) {
    delete result[key];
  }

  return result;
}

export default {
  name,
  module: {
    namespaced: true,
    state: new State(),
    actions,
    mutations
  },
  keepPersistentFields
};
