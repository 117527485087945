<template>
  <div id="app">
    <application-loading></application-loading>    
    <router-view />
  </div>
</template>

<script>
import ApplicationLoading from './components/General/ApplicationLoading';


  export default {
    name: 'Locale',
    components: {
      
      'application-loading': ApplicationLoading
    },
    data() {
      return { langs: ['fr', 'en'] };
    }
};
</script>

<style lang="scss">
  @import url("https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i&display=swap");

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/fa-brands-400.eot);
  src: url(assets/fonts/fa-brands-400.eot?#iefix) format("embedded-opentype"),
    url(assets/fonts/fa-brands-400.woff2) format("woff2"),
    url(assets/fonts/fa-brands-400.woff) format("woff"),
    url(assets/fonts/fa-brands-400.ttf) format("truetype"),
    url(assets/fonts/fa-brands-400.svg#fontawesome) format("svg");
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 300;
  src: url(assets/fonts/fa-light-300.eot);
  src: url(assets/fonts/fa-light-300.eot?#iefix) format("embedded-opentype"),
    url(assets/fonts/fa-light-300.woff2) format("woff2"),
    url(assets/fonts/fa-light-300.woff) format("woff"),
    url(assets/fonts/fa-light-300.ttf) format("truetype"),
    url(assets/fonts/fa-light-300.svg#fontawesome) format("svg");
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/fa-regular-400.eot);
  src: url(assets/fonts/fa-regular-400.eot?#iefix) format("embedded-opentype"),
    url(assets/fonts/fa-regular-400.woff2) format("woff2"),
    url(assets/fonts/fa-regular-400.woff) format("woff"),
    url(assets/fonts/fa-regular-400.ttf) format("truetype"),
    url(assets/fonts/fa-regular-400.svg#fontawesome) format("svg");
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 900;
  src: url(assets/fonts/fa-solid-900.eot);
  src: url(assets/fonts/fa-solid-900.eot?#iefix) format("embedded-opentype"),
    url(assets/fonts/fa-solid-900.woff2) format("woff2"),
    url(assets/fonts/fa-solid-900.woff) format("woff"),
    url(assets/fonts/fa-solid-900.ttf) format("truetype"),
    url(assets/fonts/fa-solid-900.svg#fontawesome) format("svg");
}

html {
  font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
      color: #353731;
  font-size: 16px;
  width: 100%;
  min-width: 1024px;

  body {
    width: 100%;
    margin: 0;
  }
}



.wrapper {
  max-width: 1600px;
  padding: 0 1rem;
  margin: 0 auto;
  }

a {
  color: #2d9ae4;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  transition: all 0.25s ease-in-out;
  cursor: pointer;

  &:hover {
    color: #1671af;
  }
}

input.input {
  display: block;
  font-size: 0.875rem;
  padding: 1rem 2rem 1rem 1rem;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #d0d4c8;
  border-radius: 4px;
}

.dropdown {
  position: relative;
  min-width: 240px;
  display: inline-block;
  width: 100%;

  select {
    display: block;
    font-size: 0.875rem;
    padding: 1rem 2rem 1rem 1rem;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #d0d4c8;
    border-radius: 4px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    &::-ms-expand {
      display: none;
    }
  }

  &:after {
    content: "\f078";
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    top: 50%;
    right: 0.75rem;
    transform: translateY(-50%);
    pointer-events: none;
    font-weight: 700;
    color: #72766a;
  }

  &.month,
  &.year,
  &.day {
    min-width: 10px;
    margin-right: 0.3125rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &.month {
    width: 4.715625rem;
  }
}

.label {
  margin-bottom: 0.5rem;
  width: 100%;
}

.msgError{
  display: block;
  color: #FF5F5F;
  margin-top: 0.25rem;
}

.error{
  border-color: #FF5F5F;
  outline-color: #FF5F5F;
}

@media screen and (max-width: 1023px) {
  html {
    font-size: 10px;
    min-width: 100%;
  }
}
</style>
