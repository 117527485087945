import i18n from './i18n.js';

class LocalRouteHelper {
    getLocalRouteName(routeName, language) {
        if (!language) {
            return i18n.locale + '.' + routeName;
        }
        else {
            return language + '.' + routeName;
        }
    }

    getCurrentLocale(){
        return i18n.locale;
    }
}

export let localRouteHelper = new LocalRouteHelper();
