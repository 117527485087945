export default class State {
  constructor() {
    this.currentProject = null;
    this.countries = [];
    this.isLoadingGenerateProject = false;
    this.currentProjectIsModified = false;
    this.quotes = [];
    this.partnerIsShow = false;
    this.savedQuote = false;
    this.pastProjects=[];
    this.totalPages=0;
  }
}
