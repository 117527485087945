export default {
  setCurrentQuote(state, currentQuote) {
    state.currentQuote = currentQuote;
  },
  setPlansPrices(state, planPrices) {
    state.plansPrices = planPrices;
  },
  setPlansSettings(state, PlansSettings) {
    state.PlansSettings = PlansSettings;
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setQuoteReferenceId(state, quoteReferenceId) {
    state.quoteReferenceId = quoteReferenceId;
  },
  setQuoteReferenceSuffix(state, quoteSuffix) {
    state.quoteReferenceSuffix = quoteSuffix;
  },
  setQuoteDeleted(state, argsDeleted) {
    state.QuoteDeleted = argsDeleted;
  }
};
