export default class State {
  constructor() {

    // Champs utilisés pour la section <head>
    this.title = '';
    this.canonicalPath = '';    

    // Champs fonctionnels
    this.errors = [];
    this.brokerUserModel = {};
    this.isLoading = true;
    this.startupLoading = false;
    this.validationNameTriggered = { validationName: ''};
    this.isLoggedIn = false;
  }
}
