import { Validator } from 'vee-validate';

function isValidMonthOption(value) {
  if(!value) return false;
  return true;
  // if (Number(value) > 0 && Number(value) <= 12) {
  //   return true;
  // }
  // return false;
}

Validator.extend('validMonthOption', {
  getMessage: () => '',
  validate: value => isValidMonthOption(value)
});
