export default {
  addError(state, error) {
    state.errors.push({ message: error.message, stack: error.stack });
  },

  addCustomError(state, error) {
    state.errors.push({ message: error, stack: '' });
  },

  dismissError(state, error) {
    state.errors = state.errors.filter(x => x !== error);
  },
  setBrokerUser(state, brokerUser) {
    state.brokerUserModel = brokerUser;
  },
  setStartLoading(state, isLoading) {
    state.startupLoading = isLoading;
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  SetValidationNameTrigger(state, validationName) {
    state.validationNameTriggered = { validationName: validationName };
  },
setIsLoggedin(value){
state.isLoggedIn = value;
}

};
