import { Validator } from 'vee-validate';

function isValidMonth(value) {
  if (Number(value) > 0 && Number(value) <= 12) {
    return true;
  }
  return false;
}

Validator.extend('validMonth', {
  getMessage: () => '',
  validate: value => isValidMonth(value)
});
