import { Config } from '../config';
import { localRouteHelper } from '@/localRouteHelper';
import Router from 'vue-router';
import routes from '@/router/routes';
export default {


  async setError(context, errorCode) {
    context.commit('addError', errorCode);
  },
  async startLoading(context, value) {
    context.commit('setStartLoading', value);
  },
  async IsAuthenticated(context) {
    context.commit('setLoading', true);
    let response = await axios.get(Config.API_ENDPOINT + '/api/Token/IsAuthenticated', {});

      context.commit('setBrokerUser', response.data);
      context.commit('setLoading', false);
  },

  async Login(context, loginModel) {
    try {
      context.commit('setLoading', true);

      let response = await axios.post(Config.API_ENDPOINT + '/api/Token/Login', loginModel);
      if(response.data.isSuccess)
      {
      localStorage.auth_token =  response.data.token;
     

      this.dispatch('app/loginComplete', 500, { root: true });

   
      var lang = localRouteHelper.getCurrentLocale();

      if(lang==='fr'){
        window.location.replace('/fr/accueil');
      }
      else{
          window.location.replace('/en/home');
      }

      
      }
      else{
        context.commit('addCustomError', 'invalid User Name and Password');
        context.commit('setLoading', false);
      }

    }
    catch (e) {
      context.commit('addError', e);
      context.commit('setLoading', false);
    }
  },
  async Logout(context) {
    try {
      localStorage.auth_token ='';

      
      var lang = localRouteHelper.getCurrentLocale();

      if(lang==='fr'){
        window.location.replace('/fr/connexion');
      }
      else{
          window.location.replace('/en/login');
      }

      //window.location = "/en/login";
      //context.commit('setLoading', true);

     // await axios.get('/api/Token/LogoutBrokerUser', {});

      //let response = await axios.get('/api/Token/GetLoginUrl', {});
     // window.location.replace(response.data);
    }
    catch (e) {
      context.commit('addError', e);
      context.commit('setLoading', false);
    }
  },
  async AddValidationNameTrigger(context, validationName) {
    context.commit('SetValidationNameTrigger', validationName);
  }
};
