import { Validator } from 'vee-validate';

function isValidYear(value) {  
  if (Number(value) > 1900 && String(value).length === 4) {
    return true;
  }
  return false;
}

Validator.extend('validYear', {
  getMessage: () => '',
  validate: value => isValidYear(value)
});
