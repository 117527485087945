import AgeConfig from '../config/ageConfiguration.json';
export default {
  en: {
    messages: {
      required: () => 'This field is required',
      nowOrAfter: () => 'Make sure you choose a date that is greater than or equal to today\'s',
      validNationality: () => 'This field is required',
      email: () => 'Please specify a valid email',
      not_in: () => 'This field is required',
      min_value: field => `${field} is too low`,
      max_value: field => `${field} is too high`,
      phone: () => 'Please specify a valid phone number',
      postalCode: () => 'Please specify a valid postal code',
      falsy: () => 'The value has to be No',
      min: (field, [length]) => `The field ${field} must contain at least ${length} characters.`,
      max: (field, [length]) => `The field ${field} must contain no more than ${length} characters.`,
      integer_if: () => 'Fractional values are not accepted for this product',
      confirmed: 'The value of both fields is not the same.',
      legalAge: (field, [minAge]) => `You must be over ${minAge} years old to buy this product.`,
      validMonth: () => 'Please enter a valid month.',
      validYear: () => 'Please enter a valid year.',
      validDate: () => 'Please enter a valid date.',
      name: () => 'Please enter a valid first name or last name.',
      differentPerson: () => 'Second insured must be different than policyholder',
      underMaxAge: (field, [maxAge, message]) => `You must be ${maxAge} years of age or younger to buy this product.`
    },
    attributes: {
      quantity: 'Quantity'
    },
    custom: {
      password: {
        regex: 'Your password must have at least 8 characters, an uppercase character, a digit and a special character.'
      },
      newPassword: {
        regex: 'Your password must have at least 8 characters, an uppercase character, a digit and a special character.'
      }
    }
  },
  fr: {
    messages: {
      required: () => 'Ce champ est obligatoire',
      nowOrAfter: () => 'Veillez choisir une date supérieure ou égale à celle d\'aujourd\'hui',
      validNationality: () => 'Ce champ est obligatoire',
      email: () => 'Veuillez entrer un courriel valide',
      min_value: (field) => `${field} est trop bas`,
      max_value: (field) => `${field} est trop élevé`,
      not_in: () => 'Ce champ est obligatoire',
      phone: () => 'Veuillez entrer un téléphone valide',
      postalCode: () => 'Veuillez entrer un code postal valide',
      falsy: () => 'La valeur doit être non',
      min: (field, [length]) => `Le champ ${field} doit contenir au moins ${length} caractères.`,
      max: (field, [length]) => `Le champ ${field} doit contenir au maximum ${length} caractères.`,
      integer_if: () => 'Les valeurs fractionnelles ne sont pas acceptées pour ce produit',
      confirmed: 'La valeur des deux champs n\'est pas identique',
          legalAge: (field, [minAge, message]) => `${message} être âgé de ${minAge} ans ou plus pour acheter ce produit.`,
      validMonth: () => 'Veuillez entrer un mois valide.',
      validYear: () => 'Veuillez entrer une année valide.',
      validDate: () => 'Veuillez entrer une date valide.',
      name: () => 'Veuillez entrer un prénom ou un nom valide.',
      underMaxAge: (field, [maxAge, message]) => `Il faut être âgé de ${maxAge} ans ou moins pour acheter ce produit.`
    },
    custom: {
      password: {
        regex: 'Votre mot de passe doit avoir plus de 8 caractères et avoir au moins une majuscule, un chiffre et un caractère spécial.'
      },
      newPassword: {
        regex: 'Votre mot de passe doit avoir plus de 8 caractères et avoir au moins une majuscule, un chiffre et un caractère spécial.'
      },
      quantity: {
        min_value: 'La quantité est trop basse',
        max_value: 'La quantité est trop élevée'
      }
    }
  }
};
