export default class State {
  constructor() {
    this.currentQuote = null;
    this.plansPrices = null;
    this.PlansSettings = null;
    this.isLoadingBenefitPackagesPrices = false;
    this.quoteReferenceId = '';
    this.quoteReferenceSuffix = 1;
    this.QuoteDeleted = false;
  }
}
