import { Validator } from 'vee-validate';

function isValidDate(values) {
  var now = new Date();
  if (values.date !== null && (values.date <= new Date(now.getFullYear() - 150, now.getMonth(), now.getDay() - 1) || Number(values.day) !== values.date.getDate())) {
    return false;
  }
  return true;
}

Validator.extend('validDate', {
  getMessage: () => '',
  validate: values => isValidDate(values)
});
