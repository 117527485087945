<template>
  <div class="spinnerContent" v-show="shouldDisplayLoading">
    <div class="spinner"></div>
  </div>
</template>
<script>
  export default {
    computed: {
      isLoadingBenefitPackagesPrices() {
        return this.$store.state.quotes.isLoadingBenefitPackagesPrices;
      },
      isLoadingGenerateProject() {
        return this.$store.state.projects.isLoadingGenerateProject;
      },
      isLoadingOnCredentialVerification() {
        return this.$store.state.app.startupLoading;
      },
      isLoading() {
        return this.$store.state.app.isLoading;
      },
      shouldDisplayLoading() {
        return this.isLoadingBenefitPackagesPrices || this.isLoadingGenerateProject || this.isLoadingOnCredentialVerification || this.isLoading;
      }
    }
  };
</script>
<style lang="scss" scoped>
  .spinner{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    z-index: 6000;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+17,000000+100&1+17,0.5+100 */
    background: -moz-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 17%, rgba(0,0,0,0.5) 100%); /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 17%,rgba(0,0,0,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center,  rgba(255,255,255,1) 17%,rgba(0,0,0,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#80000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */


    &:after{      
      top: 50%;
      left: 50%;
      content: "";
      position: absolute;
      transform: translate(-50%,-50%);
      width: 100px;
      height: 100px;
      background: url('../../assets/Images/loading.gif') no-repeat center;
      border-radius: 100%;
    }
  }
</style>
