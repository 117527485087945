import Vue from 'vue';
//import '@/plugins/axios';
import App from '@/App.vue';
import router from '@/router';
import smoothscroll from 'smoothscroll-polyfill';
import store from '@/store';
import '@/registerServiceWorker';
import i18n from '@/i18n';
import VeeValidate from 'vee-validate';
import validations from '@/locales/validations.js';
import VueAnalytics from 'vue-analytics';
import Toasted from 'vue-toasted';
import axios from 'axios';
import { localRouteHelper } from '../src/localRouteHelper';

import './components/CustomRules/legalAgeRule.js';
import './components/CustomRules/underMaxAgeRule.js';
import './components/CustomRules/validDateRule.js';
import './components/CustomRules/validMonthRule.js';
import './components/CustomRules/validMonthOptionRule.js';
import './components/CustomRules/validYearRule.js';
import './components/CustomRules/validNationalityRule.js';
import './components/CustomRules/nameRule.js';
import './components/CustomRules/nowAndAfterDateRule.js';
import { Config } from './store/config';

// appSettings is defined in Startup.cs
/* global appSettings:true */

if (appSettings && appSettings.GoogleAnalyticsEnabled) {
  Vue.use(VueAnalytics, {
    id: appSettings.GoogleAnalyticsUA,
    router
  });
}

Vue.use(VeeValidate, {
  i18n,
  dictionary: validations
});

Vue.use(Toasted);

// Message App Error
let optionsError = {
  type: 'error',
  duration: 5000,
  position: 'top-right'
};

Vue.toasted.register('app_error',
  (payload) => {

    if (!payload.message) {
      return 'Oops.. Something Went Wrong..';
    }

    return payload.message;
  },
  optionsError
);

// Message App Valid
let optionsValid = {
  type: 'success',
  duration: 5000,
  position: 'top-right'
};

Vue.toasted.register('app_success',
  (payload) => {

    if (!payload.message) {
      return 'Success';
    }

    return payload.message;
  },
  optionsValid
);

// Add a request interceptor
axios.interceptors.request.use(function (config) {

  //const token = localStorage.auth_token;
  const token = localStorage.auth_token;
  // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiIxIiwiZW1haWwiOiJ0ZXN0QHRlc3QuY29tIiwicm9sZSI6IiIsIm5iZiI6MTU5MjUxMDkwMCwiZXhwIjoxNTkzMTE1Njk5LCJpYXQiOjE1OTI1MTA4OTl9.sZqRlOHeEjdPY9H89gO5LTQInLPEbsS666SMWgOBtJo';
  //const token = "";//"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiIxIiwiZW1haWwiOiJ0ZXN0QHRlc3QuY29tIiwicm9sZSI6IiIsIm5iZiI6MTU5MjUxMDkwMCwiZXhwIjoxNTkzMTE1Njk5LCJpYXQiOjE1OTI1MTA4OTl9.sZqRlOHeEjdPY9H89gO5LTQInLPEbsS666SMWgOBtJo";
if(token)
  config.headers.Authorization = 'Bearer ' + token;
  return config;
});

axios.interceptors.response.use(
  function (response) {    
    return response;
  },
  function (error) {
    
    if (error.response) {
      switch (error.response.status) {
        case 401:
          store.dispatch('app/startLoading', true);
          
          var lang = localRouteHelper.getCurrentLocale();

          if(lang=='fr'){
            window.location.replace('fr/connexion');
          }
          else{
              window.location.replace('en/login');
          }
          break;
        case 404:
          this.dispatch('app/setError', 404, { root: true });
          break;
        case 500:
          Vue.toasted.global.app_error({
            message: error.response.request.responseText,
            duration: 9000
          });
          this.dispatch('app/setError', 500, { root: true });
        break;
      }
    }

    return Promise.reject(error);
  });

Plugin.install = function (Vue) {
  Vue.axios = axios;
  window.axios = axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return axios;
      }
    },
    $axios: {
      get() {
        return axios;
      }
    }
  });
};

Vue.use(Plugin);

smoothscroll.polyfill();

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
