import { Validator } from 'vee-validate';
import { isNullOrUndefined } from 'util';

function isNowOrAfter(date) {
    if (!isNullOrUndefined(date)) {
          return date >= new Date();
          }
            return true;
          }
          Validator.extend('nowOrAfter', {
              getMessage: () => '',
                validate: (date) => isNowOrAfter(date)
              }, {
                    hasTarget: true
                    });