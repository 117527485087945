import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import app from './app/appModule.js';
import projectModule from './projects/projectModule.js';
import quotesModule from './quotes/quotesModule.js';


Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: actions,
  modules: {
    [app.name]: app.module,
    [projectModule.name]: projectModule.module,
    [quotesModule.name]: quotesModule.module
  }  
});
