import axios from 'axios';

import { Config } from '../config';

export default {

  async getPlansPrices(context) {

    context.commit('setLoading', true);

    try {
      let response = await axios.post(Config.API_ENDPOINT + '/api/Project/GetBenefitPackagesPrices');
      context.commit('setPlansPrices', response.data);
    }
    catch (e) {
      this.dispatch('app/setError', 500, { root: true });
    }
    finally {
      context.commit('setLoading', false);
    }
  },

  async getPlansSettings(context) {

    context.commit('setLoading', true);

    try {
      let response = await axios.post(Config.API_ENDPOINT + '/api/Project/GetBenefitSettings');
      context.commit('setPlansSettings', response.data);
    }
    catch (e) {
      this.dispatch('app/setError', 500, { root: true });
    }
    finally {
      context.commit('setLoading', false);
    }
  },

  

  async setCurrentQuote(context, currentQuote) {
    context.commit('setCurrentQuote', currentQuote);
  },
  async getQuoteRefId(context, quotePrefix) {
    context.commit('setLoading', true);
    try {
      let response = await axios.post(Config.API_ENDPOINT + '/api/Project/GetQuoteRefId', quotePrefix);      
      context.commit('setQuoteReferenceId', response.data);
    }
    catch (e) {
      this.dispatch('app/setError', 500, { root: true });
    }
    finally {
      context.commit('setLoading', false);
    }
  },
  async setQuoteReferenceSuffix(context, quoteSuffix) {
    context.commit('setQuoteReferenceSuffix', quoteSuffix);
  },
  async deleteQuote(context, deleteQuoteArgs) {
    context.commit('setLoading', true);
    try {
      await axios.post(Config.API_ENDPOINT + '/api/Project/DeleteQuote', deleteQuoteArgs);
      
      context.commit('setQuoteDeleted', { QuoteId: deleteQuoteArgs.ArgsGuid, QuoteReferenceIdPrefix: deleteQuoteArgs.QuoteReferenceIdPrefix, QuoteReferenceIdSuffix: deleteQuoteArgs.QuoteReferenceIdSuffix});
    }
    catch (e) {
      this.dispatch('app/setError', 500, { root: true });
    }
    finally {
      context.commit('setLoading', false);
    }
  }
};
