import { Validator } from 'vee-validate';

function isValidName(value) {
  return /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/.test(value.toLowerCase());
}

Validator.extend('name', {
  getMessage: () => '',
  validate: value => isValidName(value)
});
