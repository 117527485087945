import Vue from 'vue';
import Router from 'vue-router';
import routes from '@/router/routes';
import store from '@/store';

Vue.use(Router);

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) &
    to.matched.every(record => !record.meta.bypassAuth)) {
    store.dispatch('app/IsAuthenticated').then(function () {
      next();
    });
  } else {
    next();
  }
});

export default router;
