import { Validator } from 'vee-validate';
import { isNullOrUndefined } from 'util';

function isUnderMaxAge(values) {
  if (!isNullOrUndefined(values.date)) {
    if (values.date <= values.maxDate) {
      return false;
    }
  }
  return true;
}

Validator.extend('underMaxAge', {
  getMessage: () => '',
  validate: values => isUnderMaxAge(values)
});
