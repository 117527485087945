import { Validator } from 'vee-validate';

function isValidNationality(values) {
  return !(values.isoCode === '' || values.isoCode === undefined || values.isoCode === null || values.zoneType === 0);
}

Validator.extend('validNationality', {
  getMessage: () => '',
  validate: values => isValidNationality(values)
});
