import i18n from '@/i18n';
import Localize from '@/components/Localize.vue';

function getRouteName(lang, name) {
  return `${lang}.${name}`;
}

function getRoutes(lang) {
  return [
    {
      path: '/' + lang,
      component: Localize,
      meta: { requiresAuth: true },
      beforeEnter(to, from, next) {
        if (i18n.locale !== lang) {
          i18n.locale = lang;
        }

        next();
      },
      children: [
        {
          path: i18n.t('routes.home', lang),
          name: getRouteName(lang, 'home'),
          component: () => import(/* webpackChunkName: "Projects" */ '@/views/Home.vue'),
          alias: '',
          meta: {
            title: i18n.t('titles.home', lang)
          }
        },
        {
          path: i18n.t('routes.generateProject', lang),
          name: getRouteName(lang, 'generateProject'),
          component: () => import(/* webpackChunkName: "Projects" */ '@/views/Projects/Project.vue'),
          alias: '',
          meta: {
            title: i18n.t('titles.generateProject', lang)
          }
        },
        {
          path: i18n.t('routes.error', lang),
          name: getRouteName(lang, 'error'),
          component: () => import(/* webpackChunkName: "Error" */ '@/views/Error.vue'),
          meta: {
            title: i18n.t('titles.error', lang)
          }
        },
        {
          path: i18n.t('routes.pageNotFound', lang),
          name: getRouteName(lang, 'pageNotFound'),
          component: () => import(/* webpackChunkName: "Error" */ '@/views/PageNotFound.vue'),
          meta: {
            requiresAuth:false,
            title: i18n.t('titles.pageNotFound', lang)
          }
        },
        {
          path: i18n.t('routes.login', lang),
          name: getRouteName(lang, 'login'),
          component: () => import(/* webpackChunkName: "Error" */ '@/views/Login.vue'),
          meta: {
           
            title: i18n.t('titles.login', lang)
          }
        },
        {
          path: i18n.t('routes.pastPlans', lang),
          name: getRouteName(lang, 'pastPlans'),
          component: () => import(/* webpackChunkName: "Error" */ '@/views/PastPlans.vue'),
          meta: {
           
            title: i18n.t('titles.pastPlans', lang)
          }
        },
        {
          path: '*',
          redirect: i18n.t('routes.pageNotFound', lang),
          meta: {
            requiresAuth: false,
            bypassAuth: true,
            title: i18n.t('titles.pageNotFound', lang)
          }
        }
      ]
    }
  ];
}
let routes = [].concat(
  [
    {
      path: '',
      redirect: '/fr',
      meta: { requiresAuth: true }
    }
  ],
  getRoutes('fr'),
  getRoutes('en'),
  {
    path: '*',
    redirect: i18n.t('routes.pageNotFound', 'en'),
    meta: {
      title: i18n.t('titles.pageNotFound', 'en')
    }
  }
);

export default routes;
